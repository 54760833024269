import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import FilterContext from "~context/filter"

// Import Local Components
import Project from "./components/project/_project"

const Columns = styled.div`
  position: relative;
  width: 100%;
`

const Projects = styled.div``

export default ({
  data: {
    sanityWebsite: { projects },
  },
}) => {
  const [filter] = useContext(FilterContext)
  return (
    <Columns>
      <Projects>
        {projects.map(project => {
          const projectTags = project.tags.map(({ name }) => name)
          const display =
            filter === "All" ? projects : projectTags.includes(filter)
          return (
            <Project project={project} key={project.id} display={display} />
          )
        })}
      </Projects>
    </Columns>
  )
}

export const query = graphql`
  query ProjectsPageQuery {
    sanityWebsite(id: { eq: "c69612d2-015f-5829-8e1a-e6dc9b7a3927" }) {
      projects {
        id
        title
        subTitle
        _rawSummary
        city
        role
        tags {
          name
        }
        hero {
          asset {
            id
            title
            url
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
