import React from "react"
import styled from "styled-components"
import { typography, layout, flexbox } from "styled-system"
import { Link } from "gatsby"
import Img from "gatsby-image"
import slug from "slug"
import BlockContent from "@sanity/block-content-to-react"

const Project = styled.div`
  ${layout}
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  padding: 0.75rem;
  &:first-child {
    margin-top: 0;
  }
`

const Information = styled(Link)`
  text-decoration: none;
  &:hover {
    opacity: 0.5 !important;
  }
  transition: opacity 0.3s !important;
`

const ExtraInformation = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const Title = styled.span`
  ${typography}
  box-sizing: border-box;
  font-size: 2.5rem;
  margin-bottom: -0.4rem;
  font-weight: normal;
  @media (max-width: 900px) {
    margin-top: 1.5rem;
  }
`

const Role = styled.span`
  ${typography}
  font-size: 0.9rem;
  line-height: 22.5px;
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`

const SubTitle = styled.p`
  ${typography}
  flex-basis: 33%;
  font-size: 1.25rem;
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`

const LeftColumn = styled.div`
  ${layout}
  ${flexbox}
  height: fit-content;
  box-sizing: border-box;
  @media (min-width: 900px) {
    padding-right: 1.5rem;
  }
  &:hover {
    opacity: 0.5;
  }
  &:hover ~ * {
    opacity: 0.5;
  }
  transition: 0.3s opacity;
`

const RightColumn = styled.div`
  ${layout}
  ${flexbox}
  box-sizing: border-box;
  &:hover {
    opacity: 0.5;
  }
  &:hover ~ * {
    opacity: 0.5;
  }
  transition: 0.3s opacity;
`

const Description = styled.div`
  ${typography}
  margin-top: 0.75rem;
  position: relative;
  max-height: 5rem;
  overflow: hidden;
  opacity: 0.5;
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(224, 224, 224, 0) 50%,
      rgba(214, 214, 214, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(224, 224, 224, 0) 50%,
      rgba(214, 214, 214, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(224, 224, 224, 0) 50%,
      rgba(214, 214, 214, 1) 100%
    );
  }
  @media (max-width: 900px) {
    opacity: 1;
  }
`

export default ({ project, display }) => {
  return (
    <Project display={display ? "flex" : "none"}>
      <LeftColumn width={[1, 0.5]} flexBasis={["100%", "50%"]}>
        <Information to={`/projects/${slug(project.title)}`}>
          <h2
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "2px black solid",
              paddingBottom: "0.33rem",
              marginBottom: "0.5rem",
              alignItems: "flex-end",
            }}
          >
            <Title fontFamily={"moonbase"}>{project.title}</Title>{" "}
          </h2>
          <ExtraInformation>
            <div style={{ flexBasis: "33%" }}>
              <SubTitle fontFamily={"optima"} fontWeight={"bold"}>
                {project.subTitle}
              </SubTitle>
            </div>
            <div style={{ flexBasis: "66%" }}>
              <Role fontFamily={"optima"} fontWeight={"bold"}>
                {project.role}
              </Role>
              <Description fontFamily={"optima"} fontWeight={"normal"}>
                <BlockContent blocks={project._rawSummary} />
              </Description>
            </div>
          </ExtraInformation>
        </Information>
      </LeftColumn>
      <RightColumn width={[1, 0.5]} flexBasis={["100%", "50%"]} order={[-1, 0]}>
        <Link to={`/projects/${slug(project.title)}`}>
          <Img
            fluid={project.hero.asset.fluid}
            alt={project.hero.caption}
            placeholderStyle={{ filter: "blur(20px)" }}
          />
        </Link>
      </RightColumn>
    </Project>
  )
}
